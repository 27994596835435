var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", [
    _c(
      "div",
      [
        _c("Header"),
        _c("div", { staticClass: "Tactic:" }, [
          _c("div", { staticClass: "Tactic:container" }, [
            _c(
              "div",
              { staticClass: "Tactic:container100" },
              [
                _c("div", { staticClass: "Tactic:box paramslist:" }, [
                  _c(
                    "div",
                    { staticClass: "Tactic:box-wrap" },
                    [
                      _c("div", { staticClass: "Tactic:box-title" }, [
                        _vm._v("Home")
                      ]),
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            _vm._l(_vm.tparamsHome, function(tparam, idx) {
                              return _c(
                                "v-col",
                                { key: idx, attrs: { cols: "6" } },
                                [_c("Tactic", { attrs: { tparam: tparam } })],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("Menu")
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "Tactic:container100" },
              [
                _c("div", { staticClass: "Tactic:box paramslist:" }, [
                  _c(
                    "div",
                    { staticClass: "Tactic:box-wrap" },
                    [
                      _c("div", { staticClass: "Tactic:box-title" }, [
                        _vm._v("Away")
                      ]),
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            _vm._l(_vm.tparamsAway, function(tparam, idx) {
                              return _c(
                                "v-col",
                                { key: idx, attrs: { cols: "6" } },
                                [_c("Tactic", { attrs: { tparam: tparam } })],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c("Menu")
              ],
              1
            )
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }