var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { height: "100%" } },
    [
      _c(
        "v-card-title",
        { staticClass: "text-h6  text--primary text-center" },
        [_vm._v(" " + _vm._s(_vm.extendType))]
      ),
      _c("v-card-text", { staticClass: "text-h4  text--primary text-center" }, [
        _vm._v(" " + _vm._s(_vm.formattedValue) + " ")
      ]),
      _c(
        "v-sheet",
        {
          staticClass: "v-sheet--offset mx-auto",
          attrs: {
            color: "black",
            elevation: "12",
            "max-width": "calc(100% - 32px)"
          }
        },
        [
          _c("v-sparkline", {
            attrs: {
              labels: _vm.tparam.labels,
              value: _vm.slvalues,
              color: "white",
              "line-width": "2",
              padding: "16"
            }
          })
        ],
        1
      ),
      _c("v-card-actions")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }