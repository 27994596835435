<template>
  <v-app>
    <div>
      <Header />
      <div class="Tactic:">
        <div class="Tactic:container">
          <div class="Tactic:container100">
            <div class="Tactic:box paramslist:">
              <div class="Tactic:box-wrap">
                <div class="Tactic:box-title">Home</div>
                <v-container>
                  <v-row>
                    <v-col
                      cols="6"
                      v-for="(tparam, idx) in tparamsHome"
                      :key="idx"
                    >
                      <Tactic
                        :tparam="tparam"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </div>
            <Menu />
          </div>
          <div class="Tactic:container100">
            <div class="Tactic:box paramslist:">
              <div class="Tactic:box-wrap">
                <div class="Tactic:box-title">Away</div>
                <v-container>
                  <v-row>
                    <v-col
                      cols="6"
                      v-for="(tparam, idx) in tparamsAway"
                      :key="idx"
                    >
                      <Tactic :tparam="tparam" />
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </div>
            <Menu />
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import axios from "axios";
import AdmZip from "adm-zip";
import idb from "@/mixins/idbPosts.js";

// import Datepicker from "@/components/posttype/datepicker";
import Menu from "@/components/general/nav/menu.vue";
import Tactic from "@/components/general/card/Tactic.vue";
import Header from "@/components/general/header/Header";
export default {
  data() {
    return {
      listid: "",
      startDate: "",
      bookings: [],
      postDTkey: 0,
      refreshTimer: "",
      now: "",
      deletePostdialog: false,
      sparklines: {},
      labels: ["12am", "3am", "6am", "9am", "12pm", "3pm", "6pm", "9pm"],
      value: [200, 675, 410, 390, 310, 460, 250, 240],
    };
  },
  components: {
    Menu,
    Tactic,
    Header,
    // Datepicker,
  },
  watch: {},
  computed: {
    userid() {
      return this.$store.state.user.user.id;
    },
    tparams() {
      return this.$store.state.tactic.tparams;
    },
    tparamsHome() {
      let fltParams = [];
      for (let tparam of this.tparams) {
        if (tparam.homeaway == "H") {
          const   slKey = tparam.homeaway + "|" + tparam.type + "|" + tparam.src;
          const tparamextended = {
            homeaway: tparam.homeaway,
            src: tparam.src,
            type: tparam.type,
            value: tparam.value,
            labels: this.sparklines['times'],
            slvalues: this.sparklines[slKey],
          };
          fltParams.push(tparamextended);
        }
      }
      return fltParams;
    },
    tparamsAway() {
      let fltParams = [];
      for (let tparam of this.tparams) {
        if (tparam.homeaway == "A") {
          const   slKey = tparam.homeaway + "|" + tparam.type + "|" + tparam.src;
          const tparamextended = {
            homeaway: tparam.homeaway,
            src: tparam.src,
            type: tparam.type,
            value: tparam.value,
            labels: this.sparklines['times'],
            slvalues: this.sparklines[slKey],
          };
          fltParams.push(tparamextended);
        }
      }
      return fltParams;
    },
    idgame() {
      return this.$store.state.idgame;
    },
    storeIdgame() {
      return this.$store.state.storeIdgame;
    },
  },
  methods: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    async readDataFromAPI() {
      this.now = new Date().toLocaleString();
      this.loading = true;
      // Recupera la lista di tutti i container per i match attivi
      let object = {
        liveId: this.idgame != null ? this.idgame : this.storeIdgame,
        numValues: 6,
        numGroups: 20,
      };

      axios
        .post(process.env.VUE_APP_API_URL + "/tactic", object)
        .then((response) => {
          this.loading = false;
          // Legge in ordine di match e crea la struttura per master-details
          this.sparklines = response.data;
          // Quello che viene ritornato non è un JSON ma un oggetto JAvascript
          //for (const [key, value] of Object.entries(paramsData)  ) {

          //}
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.readDataFromAPI();
    this.refreshTimer = setInterval(this.readDataFromAPI, 30000);
  },
  destroyed() {
    clearInterval(this.refreshTimer);
  },
  mounted() {},
};
</script>

<style lang="scss">
.Tactic\: {
  padding: 40px;
  &container {
    display: flex;
  }
  &box {
    color: var(--color);
    padding: 15px;
    // background-image: url("~@/assets/images/general/sfondo.jpg");
    position: relative;
    &-wrap {
      border: var(--border);
      padding: 25px;
    }
    &-title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 0px;
    }
  }
  &container {
    display: flex;
    margin-left: -20px;
    margin-right: -20px;
    overflow-x: scroll;
  }
  &container40 {
    width: 50%;
    display: flex;
    margin-left: -20px;
    margin-right: -20px;
    overflow-x: scroll;
  }
  &container60 {
    width: 50%;
    display: flex;
    margin-left: -20px;
    margin-right: -20px;
    overflow-x: scroll;
    flex-direction: column;
  }
  &container100 {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &single {
    width: (100%/3);
    padding: 20px;
    &_val {
      color: rgb(255, 255, 255);
      font-weight: bold;
      font-size: 30px;
    }
  }

  .paramslist\: {
    width: 100%;
    .Tactic\:box-wrap {
      height: 1024px;
    }
  }
}
</style>
