<template>
  <v-card height="100%">
    <v-card-title class="text-h6  text--primary text-center"> {{ extendType }}</v-card-title>
    <v-card-text class="text-h4  text--primary text-center">
      {{ formattedValue }}
    </v-card-text>
    <v-sheet
      class="v-sheet--offset mx-auto"
      color="black"
      elevation="12"
      max-width="calc(100% - 32px)"
    >
      <v-sparkline
        :labels="tparam.labels"
        :value= slvalues
        color="white"
        line-width="2"
        padding="16"
      ></v-sparkline>
    </v-sheet>
    <v-card-actions>
      <!--v-btn color="red" @click="$emit('deletePost',tparam.postId)">Delete</v-btn-->
      <!--v-btn
        color="green"
        @click="
          $emit('setPost', tparam.postId, tparam.hometeam, tparam.awayteam)
        "
        >Play</!--v-btn
      -->
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["tparam"],
  data() {
    return {
      formatter: "",
    };
  },
  computed: {
    formattedValue() {
      return this.tparam.type == "Area" || this.tparam.type == "Dominance"
        ? this.formatter.format(this.tparam.value)
        : this.tparam.value.toFixed(2);
    },
    extendType() {
      return (this.tparam.type == "Area" || this.tparam.type == "Dominance") &&
        this.tparam.src == "V"
        ? this.tparam.type + " K-Regions"
        : this.tparam.type;
    },
    slvalues() {
      //return this.tparam.slvalues;
      //const a = [33.1, 38, 41.9, 39, 31, 46, 25, 240];
      const a = this.tparam.slvalues;
      return a;
    },
  },
  created() {
    this.formatter = Intl.NumberFormat("en-US", {
      style: "percent",
      minimumFractionDigits: 2,
    });
  },
  methods: {},
};
</script>
